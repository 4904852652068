.event-highlights5 {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

.event5 {
  display: flex;
  margin-bottom: 10%;
}

.event5 .img1 {
  flex-basis: 40%;
  
}

.event5 .img1 img {
  width: 80%;
  float: right;
}

.event-highlights5 .img2 {
  width: 90%;
}

.event-highlights5 .event-title p{
  font-size: 2vw;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.event-highlights5 .event-detail p{
  font-size: 1.25vw;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  margin-right: 20%;
  margin-bottom: 5%;
}
