.membermobile .intro1 {
    margin-left: 5%;
}

.membermobile .intro1 h2 {
    margin-top: 10%;
    font-size: 10vw;
    font-weight: bolder;
}

.membermobile .intro1 p {
    font-size: 5vw;
    width: 90%;
}

.membermobile img {
    width: 90%;
    margin-left: 5%;
}

.membermobile .MemberForm {
    width: 90%;
    margin-left: 5%;
}

.membermobile .MemberForm h2 {
    margin-top: 10%;
    font-size: 10.30vw;
    font-weight: bolder;
}


.membermobile .ant-col .ant-form-item-label label {
    font-size: 5vw;
    font-weight: bolder;
}

.membermobile .ant-input {
    border: 0.5vw solid black;
    width: 100%;
}

.ant-form-item-label > label{
    font-size: 5vw;
    font-weight: bold;
}

.membermobile p {
    width: 95%;
    font-size: 4vw;
}

.membermobile .submit {
    margin-left: -15vw;
    width: 80%;
    padding: 0%;
}

.membermobile .span {
    margin-bottom: 5%;
}

.membermobile .ant-form-item .ant-form-item-label > label {
    font-size: 5vw;
}

