.tsai-mobile .tsaicontent1 {
    margin-left: 5%;
    margin-top: 10%;
    width: 90%;
}

.tsai-mobile .joining {
    margin-top: 10%;
    display: flex;
    width: 90%;
    margin-left: 10%;
    margin-bottom: 5%;
}

.tsai-mobile .joining .text{
    font-size: 7vw;
    font-weight: bolder;
    color: black;
    margin-bottom: 2.5%;
}
.tsai-mobile .joining .arrow-wrapped {
    margin-left: 2%;
    font-size: 2vw;
    margin-top: 1%;
}
.tsai-mobile .joining .arrow-wrapped .arrow {
    margin-left: 0%;
    width: 70%;
}