.page-intro-mobile {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 0%;
}

.page-intro-mobile .description{
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    flex-grow: 1;
}

.page-intro-mobile .description .title{
    margin-left: 6%;
    margin-right: 10%;
    margin-top: 8%;
    margin-bottom: 3%;
}

.page-intro-mobile .description .title p{
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
    margin-bottom: 0%;
    margin-top: 0%;
    margin-left: -2%;
    font-size: 24pt;
}

.page-intro-mobile .description .body{
    margin-left: 25%;
    margin-right: 15%;
    margin-top: 3%;
}

.page-intro-mobile .description .body p{
    font-size: 1.25vw;
    font-weight: 200;
} 

.page-intro-mobile .intro-image {
    flex-grow: 1;
    /* position: relative; */
    width: 100%;
    margin-bottom: 0%;
}

.page-intro-mobile .description .title p{
    font-size: 24pt;
}
.page-intro-mobile .description .text {
    display: flex;
    margin-top: 5%;
    margin-left: 0%;
    margin-right: 0%;
    justify-content: start;
    height: 100%;
}

.page-intro-mobile .description .text .vertical {
    margin-left: 5%;
    /* margin-top: ; */
    margin-right: 0%;
    border-left: 15px solid black;
    height: 100%;
}

.page-intro-mobile .description .text .body p{
    margin-left: -29%;
    font-size: 110%;
    font-weight: 200;
}


