.Footer {
    background-color: black;
    display: flex;
    bottom: 0;
    width: 100%;
    /* position: fixed; */
  }
  
  .Footer .foot {
    flex-basis: 50%;
    list-style-type: none;
    padding-top: 1.5%;
    padding-left: 5%;
    background-color: black;
    float: left;
    margin-bottom: 2.5%;
  }
  
  .Footer li a {
    display: block;
    color: white;
    text-align: left;
    text-decoration: underline;
    font-weight: bold;
    font-size: 2vw;
    font-family: "Arial";
  }
  
  .Footer .Contacts {
    display: flex;
    flex-direction: column;
    margin-right: 7%;
    width: 50%;
    margin-top: 5%;
  }
  
  .Footer .Contacts h2 {
    font-size: 3vw;
    color: white;
    font-weight: bold;
    text-align: left;
    margin-left: 20%;
    margin-bottom: 0%;
    padding-bottom: 0%;
    font-family: "Arial";
    align-self: flex-end;
  }
  .Footer .Contacts .icons {
    display: flex;
    float: right;
    align-self: flex-end;
    margin-bottom: 5%;
  }

  .Footer .Contacts .icons .icon {
    width: 6vw;
    padding-left: 20%;
  }
  
  .Footer .Contacts .ocf-logo {
    margin-bottom: 5%;
    width: 15%;
    position: sticky;
    align-self: flex-end;
  }
  