@import "~antd/dist/antd.css";

.events-mobile .past-events {
  margin: 0 5%;
}

.events-mobile .highlights .title h2{
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 24pt;
  margin-top: 5%;
}

.events-mobile .highlights {
  margin: 0 5%;
}

.events-mobile .past-events .intro p{
  text-align: left;
  font-size: 24pt;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 10%;
}


.event-card img {
  margin-right: auto;
  margin-left: auto;
  display: block;
  border-radius: 10px;
  width: 100%;
}

.event-card img:hover {
  cursor: pointer;
  /*temporary without the transition*/
  box-shadow: 10px 10px 5px #000300;
  -moz-box-shadow: 0px 10px 5px #000300;
  -webkit-box-shadow: 0px 10px 5px #000300;
}

.event-modal {
  width: 80%;
  min-width: 450px;
}

.modal .modal-image img {
  width: 100%;
  min-width: 180px;
  border-radius: 10pt;
  text-align: center;
}

.modal .modal-body {
  margin-top: 30px;
  text-align: left;
  font-family: Arial;
}

.modal .modal-body .modal-learn-more {
  margin: 20px 30px 0 0;
  text-align: right;
}

.modal-event-name h1 {
  font-size: 4vw;

  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 2.5%;
}

.modal-event-time h3 {
  font-size: 1.25vw;
}
.modal-event-location h3 {
  font-size: 1.25vw;
}
.modal-event-description p {
  font-size: 1.25vw;
}
.modal-event-learn-more {
  width: 90%;
  margin: 10% 10% 0 10%;
  text-align: right;
  justify-items: right;
  display: flex;
  flex-direction: row;
}

.modal-event-learn-more h1 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 3vw;
  flex-grow: 1;
  margin: 0 15px 0 0;
}
.modal-event-learn-more img {
  /*flex-grow: 1;*/
  width: 12.5%;
  height: 12.5%;
  display: block;
  margin: auto;
}

@media screen and (max-width: 900px) {
  .modal-event-time h3{
    font-size: 12pt;
  }
  .modal-event-location h3{
    font-size: 12pt;
  }
  .modal-event-description p{
    font-size: 12pt;
  }
}