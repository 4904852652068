.event4-mobile {
    display: flex;
    flex-direction: column;
}

.event4-mobile .img {
    width: 100%;
    margin-bottom: 5%;
}

.event4-mobile .event-title p{
    font-size: 20pt;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-bottom: 0%;
}

.event4-mobile .event-detail p{
    font-size: 3vh;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 200;
    margin-right: 20%;
    margin-bottom: 5%;
}
