.home-intro-mobile .title {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}


.home-intro-mobile .title h1 {
    text-align: left;
    font-size: 200%;
    padding-top: 10%;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 3%;
    flex: 1 0 50%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}
  
.home-intro-mobile .title img {
    padding: 3%;
    padding-left: 3%;
    padding-right: 3%;
    width: 100%;
    flex: 1 0 50%;
}
  
.home-intro-mobile .morePhoto {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
  
.home-intro-mobile .morePhoto img {
    padding: 1%;
    padding-left: 3%;
    padding-right: 3%;
    width: 200px;
    flex: 1 0 50%;
}

.home-intro-mobile .blockPhoto {
    display: flex;
    flex-direction: column;
}

.home-intro-mobile .blockPhoto .introTextPhoto {
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    width: 100%;
    height: 80%;
}
  
.home-intro-mobile .blockPhoto .introTextPhoto .vertical {
    margin-left: 3%;
    margin-right: 5%;
    margin-top: 6%;
    border-left: 12px solid black;
    /* height: ; */
}

.home-intro-mobile .blockPhoto .introTextPhoto p {
    text-align: left;
    font-size: 3.5vw;
    margin-top: 7%;
    padding-right: 3%;
    margin-bottom: 0%;
}

.home-intro-mobile .blockPhoto img {
    padding-left: 3%;
    padding-right: 3%;
    width: 100%;
}
  
.home-intro-mobile .largeGroupPhoto img {
    padding: 1%;
    padding-left: 3%;
    padding-right: 3%;
    width: 100vw;
}
  
.home-intro-mobile .shortSummary p {
    font-size: 3.5vw;
    text-align: center;
    padding-top: 1%;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 3%;
}