.event-highlights4 {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

.event4 {
  display: flex;
  margin-bottom: 10%;
}

.event4 .img1 {
  flex-basis: 50%;
}

.event4 .img1 img {
  width: 70%;
  float: right;
}

.event-highlights4 .img2 {
  width: 100%;
}

.event-highlights4 .event-title p{
  font-size: 2vw;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.event-highlights4 .event-detail p{
  font-size: 1.25vw;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  margin-bottom: 5%;
  margin-right: 20%;
}
