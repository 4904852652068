.officer-modal {
    position: fixed;
    /*float: left;*/
}

.officer-lists{
    width: 70%;
    display: block;
    margin: 5% auto 5% auto;
}

.officer-lists-mobile{
    width: 90%;
    display: block;
    margin: 5% auto 5% auto;
}

.officer-card img{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: block;
}

.officer-card img:hover{
    cursor: pointer;
    /*temporary without the transition*/
    box-shadow: 10px 10px 5px #000300;
    -moz-box-shadow: 0px 10px 5px #000300;
    -webkit-box-shadow: 0px 10px 5px #000300;
}

.officer-modal .modal-body {
    min-height: 250px;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
}

.officer-modal .modal-body .modal-image{
    position: relative;
    width: 50%;
    flex-basis: 50%;
    flex-grow: 1;
}

.officer-modal .modal-body .modal-content{
    position: relative;
    width: 50%;
    flex-basis: 50%;
    flex-grow: 1;
    margin-right: 10%;
}

.officer-modal .modal-body .modal-image img{
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.modal-body .modal-content{
    font-family: Arial;
}

.modal-body .modal-content h1{
    font-size: 5.5vw;
    /*line-height: 100pt;*/
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
}

.modal-body .modal-content h2{
    font-size: 1.75vw;
    text-transform: uppercase;
}

.modal-body .modal-content p {
    font-size: 1.5vw;
}

.modal-body-mobile {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.modal-body-mobile .modal-content h1{
    margin: 0%;
}

.modal-body-mobile .modal-content h2{
    font-size: 12pt;
    margin-bottom: 5%;
}

.modal-body-mobile .modal-content p {
    font-size: 10pt;
    margin: 0%;
}

.apply-text-mobile {
    display: flex;
}

.apply-text-mobile h1{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 7vw;
    margin-left: 5%;
}

.apply-text-mobile img{
    width: 8%;
    height: 8%;
    margin-top: 1%;
    margin-left: 2%;
    display: block;
}

.grad-major{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.graduation-detail{
    flex-grow: 1;
}

.major-detail{
    flex-grow: 1;
}

.apply-text {
    width: 80%;
    margin: 0 10% 5% 10%;
    text-align: right;
    justify-items: right;
    display: flex;
    flex-direction: row;
}

.apply-text h1{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 3vw;
    flex-grow: 1;
    margin: 0 10px 0 0;
}
.apply-text img{
    /*flex-grow: 1;*/
    width: 40px;
    height: 40px;
    display: block;
    margin: auto;
}

.modal-mobile {
    margin-left: 5%;
    margin-bottom: 5%;
}

