.resourcesmobile {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-top: 8%;
    margin-bottom: 10%;
}


.resourcesmobile .eachlink {
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;

}

.resourcesmobile .eachlink .resourcelink {
    font-size: 6vw;
    color: black;
    font-weight: bolder;
    
}

.resourcesmobile .eachlink .horizontal {
    height: 0.7vh; 
    margin-top: -1.25%;
    background: #000;
    width: 83%;
}

.resourcesmobile .eachlink .horizontal1 {
    height: 0.7vh; 
    margin-top: -1%;
    background: #000;
    width: 75%;
}

.resourcesmobile .eachlink .horizontal2 {
    height: 0.7vh; 
    margin-top: -0.7%;
    background: #000;
    width: 90%;
}

.resourcesmobile .eachlink .horizontal3 {
    height: 0.7vh; 
    margin-top: -0.5%;
    background: #000;
    width: 84%;
}

.resourcesmobile .eachlink .horizontal4 {
    height: 0.7vh; 
    margin-top: -0.25%;
    background: #000;
    width: 45%;
}

.resourcesmobile .eachlink .horizontallast {
    height: 0.7vh; 
    margin-top: -1%;
    background: #000;
    width: 65%;
}

.resourcesmobile .eachlink .horizontalbottom {
    height: 0.7vh; 
    margin-top: -0.5%;
    background: #000;
    width: 35%;
}

.resourcesmobile .secret .resourcelink{
    font-size: 4vw;
    color: white;
    font-weight: bolder;

}