.event3-mobile {
    display: flex;
    flex-direction: column;
}

.event3-mobile .imgs  {
    display: flex;
    width: 100%;
}

.event3-mobile .imgs .img1 {
    width: 30%;
    margin-bottom: 5%;
    margin-right: 5%;
}

.event3-mobile .imgs .img2 {
    width: 65%;
    margin-bottom: 5%;
}


.event3-mobile .event-title p{
    font-size: 20pt;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-bottom: 0%;
}

.event3-mobile .event-detail p{
    font-size: 3vh;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 200;
    margin-right: 20%;
    margin-bottom: 5%;
}
