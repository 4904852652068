.event-highlights2 {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

.event2 {
  display: flex;
  margin-bottom: 10%;
}

.event2 .img2 {
  flex-basis: 50%;
}

.event2 .img2 img {
  width: 100%;
}

.event-highlights2 .img1 {
  width: 85%;
}

.event-highlights2 .event-title p{
  font-size: 2vw;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.event-highlights2 .event-detail p{
  font-size: 1.25vw;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  margin-right: 20%;
  margin-bottom: 5%;
}
