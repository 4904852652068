.contactsmobile .imgtext {
    margin-left: 5%;
    margin-top: 5%;
    width: 90%;
}

.contactsmobile .imgtext h2 {
    font-weight: bolder;
    font-size: 8vw;
    margin-bottom: 0%;
}

.contactsmobile .imgtext p {
    font-size: 4vw;
}

.contactsmobile .imgtext .icons {
    display: flex;
    margin-bottom: 10%;
}

.contactsmobile .imgtext .icons .icon {
    width: 80%;
}

.contactsmobile .imgtext .contactus {
    background-color: black;
    float: right;
    border-radius: 25px;
    font-size: medium;
    width: 25vw;
    margin-bottom: 10%;
}

