.Footer-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: black;

}

.Footer-mobile .foot {
    margin-top: 5%;
    margin-left: -10%;
    background-color: black;
    float: center;
}

.Footer-mobile .foot .links {
    margin-bottom: 2.5%;
}

.Footer-mobile li a {
    display: block;
    color: white;
    text-align: center;
    text-decoration: underline;
    font-weight: bold;
    font-size: 5vw;
    font-family: "Arial";
}

.Footer-mobile .Contacts {
    display: flex;
    flex-direction: column;
    margin-right: 7%;
    width: 100%;
}

.Footer-mobile .Contacts .icons {
    display: flex;
    margin-bottom: 5%;
    margin-left: 8%;
  }

  .Footer-mobile .Contacts .icons .icon {
    width: 20vw;
    padding-left: 30%;
  }
  
  .Footer-mobile .Contacts .ocf-logo {
    margin-bottom: 5%;
    margin-left: 35%;
    width: 30%;
  }
  
