.mainmobile {
    margin-left: 5%;    
    margin-top: 5%;
}

.mainmobile .titles h1 {
    font-weight: bolder;
    font-size: 10vw;
}

.mainmobile .titles .title {
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
}

.mainmobile .titles .title .description h2 {
    font-weight: bolder;
    font-size: 4vw;
} 

.mainmobile .titles .title .description p {
    font-size: 3vw;
} 

.mainmobile .titles .title .vertical {
    margin-left: 0%;
    margin-right: 5%;
    border-left: 12px solid black;
}

.mainmobile .ant-col .ant-form-item-label label {
    font-size: 6vw;
}

.mainmobile .ant-input {
    border-radius: 25px;
    width: 90%;
}

.mainmobile .position h2 {
    font-weight: bolder;
    font-size: 6vw;
}

.mainmobile .position p {
    margin-left: 0%;
    font-size: 3vw;
}

.mainmobile .position .position-select {
    margin-left: 0%;
    width: 90%;
}


.mainmobile .responses .one {
    width: 100%;
}

.mainmobile .responses .one .text {
    margin-right: 0%;
    width: 90%;
    font-size: 4vw;
}

.mainmobile .responses h2 {
    font-weight: bolder;
    font-size: 6vw;
}

.mainmobile .ant-form-item textarea.ant-input {
    width: 90%;
}

.mainmobile h2 {
    font-weight: bolder;
    font-size: 6vw;
}

.mainmobile .meeting .text{
    margin-left: 0%;
    font-size: 4vw;
}

.mainmobile .time {
    display: flex;
    flex-direction: column;
}

.mainmobile .time .text {
    display: flex;
    width: 100%;
    margin-right: 0%;
}

.mainmobile .time .text .p1 {
    width: 50%;
    margin-right: 5%;
    font-size: 4vw;
}

.mainmobile .time .text .p2 {
    width: 40%;
    font-size: 4vw;
}

.mainmobile .time .options {
    width: 100%;
    font-size: 10vw;
}

.mainmobile .ant-row .ant-form-item .options {
    margin-left: 100%;
}

.mainmobile .submit {
    background-color: black;
    border-radius: 25px;
    margin-left: -35%;
    font-size: large;
    width: 80%;
    padding: 0%;
}