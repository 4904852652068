p{
    font-size: 18pt;
    font-weight: 400;
}

img {
    width: 100%;
}

.sponsor-list {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-right: 1%;
    margin-left: 1%;
}