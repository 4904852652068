
.MemberIntros {
    display: flex;
    margin-left: 10%;
    margin-top: 7%;
    margin-bottom: 7%;
}

.MemberIntros .intro1 {
    display: flex;
    flex-direction: column;
    width: 35%;
    margin-right: 5%;
}

.MemberIntros .intro1 h2 {
    font-size: 3.25vw;
    font-weight: bolder;
}

.MemberIntros .intro1 p {
    font-size: 1.25vw;
}

.MemberForm {
    margin-left: 10%;
}

.MemberForm h2 {
    font-size: 3.25vw;
    font-weight: bolder;
}

.MemberForm .type {
    display: flex;
}

.MemberForm .ant-col .ant-form-item-label label {
    font-size: 2.5vw;
    font-weight: bold;
}

.MemberForm .ant-form-item .ant-form-item-label > label {
    font-size: 2.5vw;
    font-weight: bold;
}

.MemberForm .type p{
    width: 40%;
    margin-right: 10%;
    font-size: 1.25vw;
}

.MemberForm .ant-input {
    border: 0.25vw solid black;
    border-radius: 25px;
    width: 80%;
}

.MemberForm .submit {
    background-color: black;
    border-radius: 25px;
    font-size: large;
    width: 40%;
    margin-left: 50%;
    padding: 0%;
}