.main {
    display: flex;
    flex-direction: column;
    margin-left: 0%;
}

.main .titles {
    display: flex;
    margin-left: 10%;
}


.main .titles div.text {
    margin-bottom: 5%;
    margin-right: 0%;   
    width: 100%; 
}

.main .titles .text h1 {
    margin-bottom: 0;
    font-size: 4.5vw;
    font-weight: bolder;
}

.main .titles .title {
    margin-top: 1%;
    margin-right: 7%;
    display: flex;
}

.main .titles .title .vertical {
    margin-left: 10%;
    margin-right: 0%;
    border-left: 12px solid black;
    height: 90%;
}

.main .titles .title .description {
    margin-left: 5%;
}

.main .titles .title .description h2 {
    font-weight: bolder;
    font-size: 2.5vw;
}

.main .titles .title .description p {
    font-size: 1.5vw;
}

.main .ant-input {
    border-radius: 25px;
    width: 80%;
}

.main .ant-col .ant-form-item-label label{
    font-size: 2vw;
}

.main .rows {
    width: 90%;
    margin-left: 10%;
    margin-right: 20%;
}

.main .position {
    margin-bottom: 5%;
    margin-left: 0%;
}

.main .position .text {
    margin-left: 10%;
    font-size: 2vw;
}

.main .position .descrip{
    margin-bottom: 0%;
    margin-left: 10%;
    margin-top: 0%;
    font-size: 2vw;
}

.main .position .last{
    margin-left: 10%;
    margin-top: 0%;
    font-size: 2vw;
}

.main .ant-form-item-label > label {
    font-size: 2vw;
}

.main .position .position-select {
    margin-left: 10%;
    width: 60%;
}

.main .responses {
    margin-bottom: 5%;
    margin-left: 10%;
    width: 80%
}

.main .responses .text {
    width: 70%;
}

.main .meeting {
    display: flex;
    margin-bottom: 5%;
}

.main .meeting .text{
    margin-left: 10%;
    width: 40%;
}

.main .meeting .bubble{
    margin-top: 2%;
    margin-left: 15%;
}

.main .time {
    display: flex;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 5%;
}

.main .time .text{
    width: 60%;
    margin-right: 20%;
}

.main .time .options {
    width: 40%;
    font-size: 4vw;
}

.main .submit {
    background-color: black;
    border-radius: 25px;
    font-size: large;
    width: 40%;
    padding: 0%;
}