.main {
    margin-left: 20%;
}

.join .joinblock1 {
    display: flex;
    width: 100%;
    margin-top: 8%;
}

.join .joinblock1 .img1{
    display: flex;
    max-width: 50%;
    margin-right: 10%;
}

.join .joinblock1 .text1 {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.join .joinblock1 .text1 h2 {
    margin-top: 8%;
    margin-bottom: 2%;
    font-weight: bolder;
    font-size: 4vw;
}

.join .joinblock1 .text1 p {
    width: 80%;
    font-size: 1.25vw;
}

.join .joinblock2 {
    display: flex;
    width: 100%;
}

.join .joinblock2 .img1{
    display: flex;
    max-width: 50%;
}

.join .joinblock2 .text1 {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 10%;
    margin-top: 2%;
}

.join .joinblock2 .text1 h2 {
    margin-bottom: 2%;
    font-weight: bolder;
    font-size: 4vw;
}

.join .joinblock2 .text1 p {
    width: 80%;
    font-size: 4vw;
    font-size: 1.25vw;
}

.member {
    background-color: black;
    border-radius: 25px;
    font-size: large;
    width: 20%;
}