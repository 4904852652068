.home-intro .title {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.home-intro .title h1 {
  text-align: left;
  font-size: 400%;
  padding-top: 10%;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 3%;
  flex: 1 0 50%;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.home-intro .title img {
  padding:0;
  padding-left:0;
  padding-right:0;
  padding-bottom: 3%;
  width: 500px;
  flex: 1 0 50%;
}

.home-intro .morePhoto {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.home-intro .morePhoto img {
  padding: 1%;
  padding-left: 3%;
  padding-right: 3%;
  width: 200px;
  flex: 1 0 50%;
}

.home-intro .introTextPhoto {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.home-intro .introTextPhoto p {
  text-align: left;
  font-size: 20px;
  padding-top: 7%;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 7%;
  flex: 1 0 40%;
}

.home-intro .introTextPhoto .vertical {
  /* margin-left: 20%;
  margin-right: 0%; */
  border-left: 12px solid black;
  height: 100%;
}

.home-intro .introTextPhoto img {
  padding: 1%;
  padding-left: 3%;
  padding-right: 3%;
  width: 200px;
  flex: 1 0 40%;
}

.home-intro .largeGroupPhoto img {
  padding: 1%;
  padding-left: 3%;
  padding-right: 3%;
  width: 100vw;
}

.home-intro .shortSummary p {
  font-size: 20px;
  text-align: center;
  padding-top: 1%;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 3%;
}