.resources {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-top: 8%;
    margin-bottom: 10%;
}

.resources .eachlink {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.7%;
}

.resources .eachlink .resourcelink {
    font-size: 4vw;
    color: black;
    font-weight: bolder;
    
}

.resources .eachlink .horizontal {
    height: 0.75vh; 
    margin-top: -1.25%;
    background: #000;
    width: 57%;
}

.resources .eachlink .horizontal1 {
    height: 0.75vh; 
    margin-top: -1%;
    background: #000;
    width: 52%;
}

.resources .eachlink .horizontal2 {
    height: 0.75vh; 
    margin-top: -1%;
    background: #000;
    width: 62%;
}

.resources .eachlink .horizontal3 {
    height: 0.75vh; 
    margin-top: -1.25%;
    background: #000;
    width: 84%;
}

.resources .eachlink .horizontallast {
    height: 0.75vh; 
    margin-top: -0.9%;
    background: #000;
    width: 46%;
}

.resources .eachlink .horizontal4 {
    height: 0.75vh; 
    margin-top: -1.2%;
    background: #000;
    width: 32%;
}

.resources .secret .resourcelink{
    font-size: 4vw;
    color: white;
    font-weight: bolder;

}