.contacts .descriptions {
    margin-top: 5%;
}

.contacts .descriptions .imgtext {
    display: flex;
}

.contacts .descriptions .imgtext .textbox {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 5%;
    margin-top: 5%;
}

.contacts .descriptions .imgtext .mainphotos {
    max-width: 50%;
}

.contacts .descriptions h2 {
    font-weight: bolder;
    font-size: 4vw;
}

.contacts .descriptions p {
    font-size: 1.5vw;
    width: 80%;
}

.contacts .descriptions .icons {
    display: flex;
}

.contacts .descriptions .icons .icon {
    width: 80%;
}

.contacts .descriptions .imgtext .contactus {
    background-color: black;
    border-radius: 25px;
    font-size: 1.5vw;
    width: 25%;
    padding: 0;
}