.NavBar {
  width: 100%;
  height: 8 0px;
}
.NavBarLogo {
  width: 30%;
}
.NavBar-Options {
  padding: 0% 0%;
  margin-right: 5%;
}

.NavBar-Options a{
  color: black;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.25vw;
  font-family: "Arial";
}

#nav li{
  display: inline;
}
.NavBarLogo {
  max-width: 100px;
  float: left;
}
.NavBar{
  display: flex;
  flex-direction: row;
  margin-top: 2%;
}

.NavBar #tsa-logo{
  flex-grow: 0.5;
  margin-left: 7.5%;
}
.NavBar ul {
  flex-grow: 1.5;
  position: relative;
  list-style-type: none;
  padding: 0px;
  margin-right:0％;
  height: 80px;
  background-color: white;
  align-content: center;
}

.NavBar-Options {
  float: right;
  display: block;
}


.icon {
  width: 100%;
}

#ocf-logo {
  margin-bottom: 5%;
  width: 80%;
}
