.membersubmit h2 {
    text-align: center;
    font-size: 3vw;
    font-weight: bolder;
}

.membersubmit p {
    text-align: center;
    margin-left: 20%;
    font-size: 2vw;
    width: 60%;
}

.membersubmit img {
    margin-left: 40%;
    margin-bottom: 5%;
    width: 15%;
    height: 30%;
}

.membersubmit .submit {
    background-color: black;
    border-radius: 25px;
    font-size: large;
    width: 20%;
    padding: 0%;
}