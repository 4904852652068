.NavBar-mobile {
    display: flex;
    width: 100%;
}

.NavBar-mobile .NavBarLogo-mobile {
    margin-left: 35vw;
    margin-top: 5vh;
    width: 30%;
    height: 70%;
}

.NavBar-mobile .menu {
    background-color: white;
    border-color: white;
    box-shadow: none;
}

.NavBar-mobile .menu {
    box-shadow: none;
    font-size: 20px;
    margin-top: 7%;
    margin-right: 7%;
}

.NavBar-mobile .menu .buttonham {
    font-size: 35px;
    color: black;

}

.ant-drawer-content-wrapper {
    height: 50vh !important;
}

.ant-drawer .ant-drawer-content .nav {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-left: 0%;
}

.ant-drawer .ant-drawer-content .NavBar-Options a {
    display: block;
    color: black;
    text-align: left;
    margin-left: 0%;
    text-decoration: underline;
    font-weight: bold;
    font-size: 5vw;
    font-family: "Arial";
}
