.membersubmit-mobile {
    margin-top: 10%;
}

.membersubmit-mobile h2 {
    text-align: center;
    font-size: 6vw;
    font-weight: bolder;
}

.membersubmit-mobile p {
    text-align: center;
    margin-left: 5%;
    font-size: 4vw;
    width: 90%;
}

.membersubmit-mobile img {
    margin-left: 35%;
    margin-bottom: 5%;
    width: 30%;
    height: 60%;
}

.membersubmit-mobile .submit {
    background-color: black;
    border-radius: 25px;
    font-size: large;
    width: 20%;
    padding: 0%;
    margin-left: 40%;
}
