
.page-intro {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}
.page-intro .description{
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  flex-grow: 1;
}

.page-intro .description .title{
  margin-left: 20%;
  margin-right: 10%;
  margin-top: 0%;
  margin-bottom: 5%;
}

.page-intro .description .title p{
  text-align: left;
  font-size: 5vw;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 0;
}

.page-intro .description .body{
  margin-left: 5%;
  margin-right: 15%;
  margin-top: 3%;
}

.page-intro .description .body p{
  font-size: 1.25vw;
  font-weight: 4vw;

}

.page-intro .intro-image {
  flex-grow: 1;
  position: relative;
  width: 50%;
  flex-basis: 50%;
}

.page-intro .intro-image img {
  width: 100%;
}

.page-intro .description .text {
  display: flex;
}

.page-intro .description .text .vertical {
  margin-left: 20%;
  margin-right: 0%;
  border-left: 12px solid black;
  height: 100%;
}