.sponsors-mobile .sponsor-intro p {
    font-size: 4vw;
}

.sponsors-mobile .ant-row {
    display: flex;
    flex-direction: column;
    margin-right: 0 !important; 
    padding: 0;
}

.sponsors-mobile .sponsor-list {
    margin-left: 2%;
    padding-left: 2%;
}