.internsubmitted {
    margin-bottom: 5%;
}

.internsubmitted h2 {
    font-weight: bolder;
    font-size: 5vw;
    text-align: center;
    width: 70%;
    margin-left: 15%;
}

.internsubmitted p {
    font-size: 2vw;
    text-align: center;
    width: 70%;
    margin-left: 15%;
}