.event-highlights3 {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

.event3 .p {
  text-align: left;
  font-size: 2vw;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.event3 {
  display: flex;
  margin-bottom: 10%;
}

.event3 .img1 {
  flex-basis: 50%;
}

.event3 .img1 img {
  width: 60%;
}

.event-highlights3 .img2 {
  width: 100%;
}

.event-highlights3 .event-title p{
  font-size: 2vw;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.event-highlights3 .event-detail p{
  font-size: 1.25vw;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  margin-bottom: 5%;
  margin-right: 20%;
}
