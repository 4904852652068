.internsubmitted-mobile {
    margin-bottom: 5%;
}

.internsubmitted-mobile h2 {
    font-weight: bolder;
    font-size: 8vw;
    text-align: center;
    width: 80%;
    margin-left: 8%;
}

.internsubmitted-mobile p {
    font-size: 5vw;
    text-align: center;
    width: 70%;
    margin-left: 15%;
}