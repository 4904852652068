.joinmobile .block1 {
    margin-top: 8%;
    margin-left: 5%;
    margin-right: 5%;
}

.joinmobile .block1 h2 {
    margin-top: 8%;
    margin-bottom: 0%;
    font-weight: bolder;
    font-size: 8vw;
}

.joinmobile .block1 p {
    font-size: 4vw;
}

.joinmobile .block1 .member {
    background-color: black;
    float: right;
    border-radius: 25px;
    font-size: medium;
    width: 25vw;
    margin-bottom: 10%;

}