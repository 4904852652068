.tsai .tsaicontent1 {
    display: flex;
    margin-top: 10%;
    width: 100%;
}

.tsai .tsaicontent1 .img1 {
    width: 20000%;
    max-width: 50%;
}

.tsai .tsaicontent1 p {
    margin-left: 10%;
    margin-top: 10%;
    font-size: 1.75vw;
    margin-right: 10%;
}

.tsai .tsaicontent2 {
    display: flex;
    margin-bottom: 5%;
}

.tsai .tsaicontent2 img {
    max-width: 50%;
    width: 2000%;
}

.tsai .tsaicontent2 p {
    max-width: 50%;
    margin-left: 10%;
    margin-top: 10%;
    margin-right: 10%;
    font-size: 1.75vw;
}

.tsai .joining {
    display: flex;
    width: 45%;
    margin-left: 55%;
    margin-bottom: 5%;
}

.tsai .joining .text{
    font-size: 3vw;
    font-weight: bolder;
    color: black;
    margin-bottom: 2.5%;
}
.tsai .joining .arrow-wrapped {
    margin-left: 2%;
    font-size: 2vw;
    margin-top: 1%;
}
.tsai .joining .arrow-wrapped .arrow {
    margin-left: 0%;
    width: 70%;
}